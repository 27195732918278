/*----------------------------------------------------
@File: Default Styles
@Author: Spondon IT

This file contains the styling for the actual theme, this
is the file you need to edit to change the look of the
theme.
---------------------------------------------------- */

/*=====================================================================
@Template Name: Builder Construction 
@Author: Spondon IT
@Developed By: Naim Lasker

@Default Styles

Table of Content:
01/ Variables
02/ predefin
03/ header
04/ button
05/ banner
06/ breadcrumb
07/ about
08/ team
09/ project 
10/ price 
11/ team 
12/ blog 
13/ video  
14/ features  
15/ career  
16/ contact 
17/ footer

=====================================================================*/ 

/*----------------------------------------------------*/
@import "variables";
/*---------------------------------------------------- */ 

/*----------------------------------------------------*/
@import "reset";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "predefine";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "header";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "banner";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "home";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "breadcrumb";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "blog";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "contact";
/*---------------------------------------------------- */ 

/*----------------------------------------------------*/
@import "elements";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "button";
/*---------------------------------------------------- */

/*----------------------------------------------------*/
@import "timer";
/*---------------------------------------------------- */ 

/*----------------------------------------------------*/
@import "footer";
/*---------------------------------------------------- */





