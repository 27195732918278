.section_gap {
    padding: 150px 0px;
    @media(max-width: 991px) {
        padding: 100px 0px;
    }
}

.section_gap_top {
    padding-top: 150px;
    @media(max-width: 991px) {
        padding-top: 100px;
    }
}

.section_gap_bottom {
    padding-bottom: 150px;
    @media(max-width: 991px) {
        padding-bottom: 100px;
    }
}

.section-title-wrap {
    text-align: center;
    margin-bottom: 80px;
    @media(max-width: 991px) {
        margin-bottom:50px;
    }
    h5 {
        margin-bottom: 25px;
        font-size: 12px;
        font-weight: 400;
        color: $primary-color;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
    h2 {
        font-size: 42px;
        font-weight: 700;
        text-transform: uppercase;
        margin: 0;
        @media (max-width: 991px) {
            font-size: 30px;
        }
        br {
            @media(max-width: 991px) {
                display: none;
            }
        }
    }
}

.overlay {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.owl-dots {
    text-align: -webkit-center;
    text-align: center;
    @media(max-width: 992px) {
        display: none;
    }
    .owl-dot {
        width: 14px;
        height: 14px;
        background: #eeeeee;
        display: inline-block;
        margin-right: 10px;
        &:last-child {
            margin-right: 0;
        }
        &.active {
            background: $title-color;
        }
    }
}